// @flow
import React from "react";
import LayoutContainer from "components/LayoutContainer";

function MainScreen() {
  return (
    <>
      <LayoutContainer
        id="cameraContainer"
        size="big"
      />
    </>
  )
}
export default MainScreen;